import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { TreeView, TreeItem } from "@mui/lab";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import backgroundStyle from "./Reports.style";
import { QuickBarContext } from "../context/QuickBarContext";
import schoolsService from "../service/schoolsService";
import Authorize from "../components/Authorize";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import staffsService from "../service/staffsService";
import TermSelect from "../components/TermSelect";
import HomeroomTeacherSelect from "../components/Reports/StudentProgress/HomeroomTeacherSelect";
import TeacherSelect from "../components/Reports/ProgressReport/TeacherSelect";
import moment from "../utils/constants/momentConfig";
import dateTimeFormats from "../utils/constants/dateTimeFormats";
import StudentSelect from "../components/Reports/StudentProgress/StudentSelect";
import ProgressStudentSelect from "../components/Reports/ProgressReport/StudentSelect";
import { blueOutlinedButton, mt35 } from "../components/sharedStyles";
import reportsService from "../service/reportsService";
import { SnackbarContext } from "../context/SnackbarContext";
import lookupService from "../service/lookupService";
import GradeLevelSelect from "../components/Reports/ProgressReport/GradeLevelSelect";

export default function Reports() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedMenu, setSelectedMenu] = useState(1);
  const printComponent = useRef(null);
  const timeout = useRef();
  const quickBarContext = useContext(QuickBarContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [currentSchool, setCurrentSchool] = useState(null);
  const { hasAnyPermissionType } = useContext(PermissionsContext);
  const [hasHomeroomStudents, setHasHomeroomStudents] = useState(false);
  const [homeroomTeachers, setHomeroomTeachers] = useState([]);
  const [teacherStudents, setTeacherStudents] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState("select");
  const [selectedTeacherStudent, setSelectedTeacherStudent] = useState("all");
  const [schoolDaysByMonth, setSchoolDaysByMonth] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("all");
  const [loading, setLoading] = useState(null);
  const params = useParams();
  const snackbarContext = useContext(SnackbarContext);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [selectedGradeLevel, setSelectedGradeLevel] = useState();

  const manageAllStudentsPermission = hasAnyPermissionType(
    APP_PERMISSIONS.ALL_STUDENTS
  );

  const reportRoutes = {
    1: `/school/${params.school_id}/reports`,
    2: `/school/${params.school_id}/reports/attendance`,
    3: `/school/${params.school_id}/reports/birthdays`,
    4: `/school/${params.school_id}/reports/class-roster`,
    5: `/school/${params.school_id}/reports/emergency-info`,
    6: `/school/${params.school_id}/reports/enrollment`,
    7: `/school/${params.school_id}/reports/homeroom-roster`,
    8: `/school/${params.school_id}/reports/report-assignments`,
    9: `/school/${params.school_id}/reports/parent-contacts`,
    10: `/school/${params.school_id}/reports/school-calendar`,
    11: `/school/${params.school_id}/reports/student-progress`,
    12: `/school/${params.school_id}/reports/student-list`,
    13: `/school/${params.school_id}/reports/progress-report`,
  };

  const handleMissingAssignmentsCheckbox = (e) => {
    if (e.target.checked === true) {
      searchParams.set("showMissingAssignments", true);
      setSearchParams(searchParams);
    } else {
      searchParams.delete("showMissingAssignments");
      setSearchParams(searchParams);
    }
  };

  const getSchool = async () => {
    const response = await schoolsService.fetchSchool(params.school_id);

    setCurrentSchool(response.data);
  };

  const checkHomeRoomStudents = async () => {
    const teacherId = selectedTeacher === "select" ? null : selectedTeacher;
    const response = await staffsService.teacherHasHomeroomStudents({
      params: {
        school_id: params.school_id,
        teacher_id: searchParams.get("homeroomTeacherId") || teacherId,
        attendance_report: selectedMenu === 2 ? true : null,
      },
    });
    setHasHomeroomStudents(response.has_homeroom_students);
    setTeacherStudents(response.homeroom_students_list);
    setLoading(false);
  };

  const loadGradeLevels = async () => {
    await lookupService.fetchGradeLevels().then((response) => {
      if (response?.data?.data) {
        const list = response.data.data.map(
          // eslint-disable-next-line no-shadow
          (gradeLevel) => gradeLevel.name
        );
        let gradeLevelsList = [...new Set(list)].sort();
        const gradeOrder = [
          "PK",
          "TK",
          "K",
          ...Array.from({ length: 12 }, (_, i) => (i + 1).toString()),
        ];
        gradeLevelsList = gradeOrder.filter((grade) =>
          gradeLevelsList.includes(grade)
        );

        setGradeLevels(gradeLevelsList);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    getSchool();
    checkHomeRoomStudents();
    loadGradeLevels();
  }, [selectedTeacher, searchParams.get("homeroomTeacherId"), selectedMenu]);

  const handlePrint = useReactToPrint({
    content: () => printComponent.current.cloneNode(true),
    pageStyle: `
      @page { size: ${
        selectedMenu === 2 ? "landscape" : "auto"
      };  margin: 5mm; }
      @media print {
        .report-footer{
          visibility: visible;
        }
      }
    `,
  });

  const handleTeacherSelect = (value) => {
    setLoading(true);
    setSelectedTeacher(value);
    setSelectedTeacherStudent("all");
  };

  const handleStudentSelect = (value) => {
    clearTimeout(timeout.current);
    const studentId = value;
    setLoading(true);

    timeout.current = setTimeout(async () => {
      setSelectedTeacherStudent(studentId);
    }, 1000);
  };

  const handleMonthSelect = (value) => {
    setLoading(true);
    const month = value;
    setSelectedMonth(month);
  };

  useEffect(() => {
    if (quickBarContext.printReport) {
      handlePrint();
      quickBarContext.cleanPrintReport();
    }
  }, [quickBarContext.printReport]);

  useEffect(() => {
    if (location.pathname === reportRoutes["2"]) {
      setSelectedMenu(2);
    } else if (location.pathname === reportRoutes["3"]) {
      setSelectedMenu(3);
    } else if (location.pathname === reportRoutes["4"]) {
      setSelectedMenu(4);
    } else if (location.pathname === reportRoutes["5"]) {
      setSelectedMenu(5);
    } else if (location.pathname === reportRoutes["6"]) {
      setSelectedMenu(6);
    } else if (location.pathname === reportRoutes["7"]) {
      setSelectedMenu(7);
    } else if (location.pathname === reportRoutes["8"]) {
      setSelectedMenu(8);
    } else if (location.pathname === reportRoutes["9"]) {
      setSelectedMenu(9);
    } else if (location.pathname === reportRoutes["10"]) {
      setSelectedMenu(10);
    } else if (location.pathname === reportRoutes["11"]) {
      setSelectedMenu(11);
    } else if (location.pathname === reportRoutes["12"]) {
      setSelectedMenu(12);
    } else if (location.pathname === reportRoutes["13"]) {
      setSelectedMenu(13);
    } else {
      setSelectedMenu(1);
    }
  }, [location.pathname]);

  const downloadParentContactsCSV = async () => {
    try {
      const response = await reportsService.parentContactsCSV();
      const blob = new Blob([response], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "parent_contacts.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      snackbarContext.setSnackbar({
        message: "CSV downloaded.",
        severity: "success",
        open: true,
      });
    } catch {
      snackbarContext.setSnackbar({
        message: "Failed to download CSV.",
        severity: "error",
        open: true,
      });
    }
  };

  const downloadStudentSchoolListCSV = async () => {
    try {
      const response = await reportsService.studentSchoolList({
        params: { term_id: searchParams.get("term"), csv: true },
      });
      const blob = new Blob([response], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "student-school-list.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      snackbarContext.setSnackbar({
        message: "CSV downloaded.",
        severity: "success",
        open: true,
      });
    } catch {
      snackbarContext.setSnackbar({
        message: "Failed to download CSV.",
        severity: "error",
        open: true,
      });
    }
  };

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.REPORT_SCREEN)}>
      <Grid container minHeight="inherit" mb={2}>
        <Grid borderRight={1} item md={3}>
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            sx={{ padding: "3rem" }}
            onNodeSelect={(e, nodeId) => {
              setSelectedMenu(Number(nodeId));
              navigate(reportRoutes[nodeId]);
            }}
            defaultExpanded={[String(selectedMenu)]}
            selected={String(selectedMenu)}
          >
            <TreeItem
              nodeId="1"
              label={<Typography fontWeight="bold">Reports</Typography>}
            >
              <TreeItem nodeId="2" label="Attendance" />
              <TreeItem nodeId="3" label="Birthdays" />
              <TreeItem nodeId="4" label="Class Roster" />
              <TreeItem nodeId="5" label="Emergency Contacts" />
              <TreeItem nodeId="6" label="Enrollment" />
              {hasHomeroomStudents && (
                <TreeItem nodeId="7" label="Homeroom Roster" />
              )}
              <TreeItem nodeId="8" label="Assignments" />
              <TreeItem nodeId="9" label="Parent Contacts" />
              <TreeItem nodeId="10" label="School Calendar" />
              <TreeItem nodeId="11" label="Student Progress" />
              <TreeItem nodeId="12" label="Student List" />
              <TreeItem nodeId="13" label="Progress Report (9-12)" />
            </TreeItem>
          </TreeView>
        </Grid>
        <Grid item md={6} borderRight={1} sx={backgroundStyle}>
          {selectedMenu === 1 && (
            <Grid style={{ padding: "2rem 1rem 0 2rem" }}>
              <Typography fontSize={32} color="black">
                Reports
              </Typography>
              <Typography>
                Please select type of report from left menu to see detail
                reports.
              </Typography>
            </Grid>
          )}
          <div ref={printComponent}>
            <Outlet
              context={[
                currentSchool?.name,
                selectedTeacher,
                selectedTeacherStudent,
                setHomeroomTeachers,
                params,
                schoolDaysByMonth,
                setSchoolDaysByMonth,
                selectedMonth,
                selectedMenu,
                currentSchool?.org_id,
                loading,
                setLoading,
                selectedGradeLevel,
              ]}
            />
          </div>
        </Grid>
        {selectedMenu === 2 && (
          <Box mt={6} ml={6}>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="homeroom-teacher-label">
                  Homeroom Teacher
                </InputLabel>
                <Select
                  labelId="homeroom-teacher-label"
                  id="homeroom-teacher"
                  label="Homeroom Teacher"
                  value={selectedTeacher}
                  disabled={loading}
                  onChange={(e) => handleTeacherSelect(e.target.value)}
                >
                  <MenuItem value="select">All teachers</MenuItem>
                  {homeroomTeachers.map((ht) => (
                    <MenuItem key={ht.id} value={ht.id}>
                      {`${ht.first_name} ${ht.last_name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box my={3}>
              <FormControl fullWidth>
                <InputLabel id="student-label">Student</InputLabel>
                <Select
                  labelId="student-label"
                  id="student"
                  label="Student"
                  value={selectedTeacherStudent}
                  disabled={loading}
                  onChange={(e) => handleStudentSelect(e.target.value)}
                >
                  <MenuItem value="all">All Students</MenuItem>
                  {teacherStudents?.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {`${s.first_name} ${s.last_name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box my={3}>
              <FormControl fullWidth>
                <InputLabel id="month-label">Select month</InputLabel>
                <Select
                  labelId="month-label"
                  id="month"
                  label="Select month"
                  value={selectedMonth}
                  disabled={searchParams.get("term") !== "null" || loading}
                  onChange={(e) => handleMonthSelect(e.target.value)}
                >
                  <MenuItem value="all">All year</MenuItem>
                  {schoolDaysByMonth.map((month) => (
                    <MenuItem
                      key={moment(month.monthYear).format(
                        dateTimeFormats.YYYYMMDD
                      )}
                      value={moment(month.monthYear).format(
                        dateTimeFormats.YYYYMMDD
                      )}
                    >
                      {moment(month.monthYear).format("MMMM")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box my={3}>
              <TermSelect
                schoolId={params.school_id}
                loading={loading}
                setLoading={setLoading}
                selectedMonth={selectedMonth}
                fetchUpcomingTerms
                defaultNull
                selectBox
                isAttendance
              />
            </Box>
          </Box>
        )}
        <Grid container item md={3}>
          {selectedMenu === 6 && (
            <Grid item md={8} pt={6} pl={4}>
              <TermSelect
                schoolId={params.school_id}
                fetchUpcomingTerms
                findCurrentTerm
              />
            </Grid>
          )}
          {selectedMenu === 7 && manageAllStudentsPermission && (
            <Box sx={{ width: "100%" }}>
              <HomeroomTeacherSelect
                schoolId={params.school_id}
                setSelectedTeacherStudent={setSelectedTeacherStudent}
                hasAllTeachersOption
              />
            </Box>
          )}
          {selectedMenu === 8 && manageAllStudentsPermission && (
            <Box sx={{ width: "100%" }}>
              <Grid item md={8} pt={6} pl={4}>
                <TermSelect
                  schoolId={params.school_id}
                  fetchUpcomingTerms
                  findCurrentTerm
                />
              </Grid>
              <HomeroomTeacherSelect
                schoolId={params.school_id}
                setSelectedTeacherStudent={setSelectedTeacherStudent}
              />
              <StudentSelect
                teacherStudents={teacherStudents}
                setSelectedTeacherStudent={setSelectedTeacherStudent}
                selectedTeacherStudent={selectedTeacherStudent}
              />
              <Grid item md={8} pt={3} pl={4}>
                <FormControlLabel
                  label="Show only missing assignments"
                  control={
                    <Checkbox
                      onChange={(e) => handleMissingAssignmentsCheckbox(e)}
                    />
                  }
                />
              </Grid>
            </Box>
          )}
          {selectedMenu === 11 && (
            <Box sx={{ width: "100%" }}>
              <Grid item md={8} pt={6} pl={4}>
                <TermSelect
                  schoolId={params.school_id}
                  fetchUpcomingTerms
                  findCurrentTerm
                  selectBox
                />
              </Grid>

              <HomeroomTeacherSelect
                schoolId={params.school_id}
                setSelectedTeacherStudent={setSelectedTeacherStudent}
              />
              <StudentSelect
                teacherStudents={teacherStudents}
                setSelectedTeacherStudent={setSelectedTeacherStudent}
                selectedTeacherStudent={selectedTeacherStudent}
              />
            </Box>
          )}

          {selectedMenu === 9 && (
            <Box width="100%" p={4}>
              <Button
                variant="outlined"
                onClick={() => downloadParentContactsCSV()}
                sx={blueOutlinedButton}
              >
                <Typography fontSize={15}>Download CSV</Typography>
              </Button>
            </Box>
          )}

          {selectedMenu === 12 && (
            <Box sx={{ width: "100%" }}>
              <Grid item md={8} pt={6} pl={4}>
                <TermSelect
                  schoolId={params.school_id}
                  fetchUpcomingTerms
                  findCurrentTerm
                />
              </Grid>

              <Box sx={mt35} p={4}>
                <Button
                  variant="outlined"
                  onClick={() => downloadStudentSchoolListCSV()}
                  sx={blueOutlinedButton}
                >
                  <Typography fontSize={15}>Download CSV</Typography>
                </Button>
              </Box>
            </Box>
          )}

          {selectedMenu === 13 && (
            <Box sx={{ width: "100%" }}>
              <TermSelect
                schoolId={params.school_id}
                findCurrentTerm
                show={false}
              />

              {manageAllStudentsPermission && (
                <TeacherSelect
                  schoolId={params.school_id}
                  setSelectedTeacherStudent={setSelectedTeacherStudent}
                  hasAllTeachersOption
                />
              )}

              <ProgressStudentSelect
                teacherStudents={teacherStudents}
                setSelectedTeacherStudent={setSelectedTeacherStudent}
                selectedTeacherStudent={selectedTeacherStudent}
              />

              <GradeLevelSelect
                gradeLevels={gradeLevels}
                selectedGradeLevel={selectedGradeLevel}
                setSelectedGradeLevel={setSelectedGradeLevel}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Authorize>
  );
}
