import React, { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  Grid,
  RadioGroup,
  Radio,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { bgWhite, pb20, pt0 } from "../sharedStyles";
import { clearIconStyle, arrowDownIconStyle } from "./GeneralInfo.styles";
import { klassFormDivider, klassFormRow } from "./KlassForm.styles";
import ErasableTextField from "../ErasableTextField";
import { requiredMessage, renderError } from "../../utils/constants/forms";

export default function GeneralInfo({
  teachers,
  subjects,
  courses,
  teacherId,
  activeKlass,
  setSelectedSubject,
  level,
  setLevel,
  currentSchool,
}) {
  const [activeTeachers, setActiveTeachers] = useState([]);
  const [coTeachers, setCoTeachers] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState(courses || []);
  const [filteredSubjectCourses, setFilteredSubjectCourses] = useState(
    filteredCourses || []
  );

  const {
    register,
    watch,
    resetField,
    control,
    formState: { errors },
  } = useFormContext();

  const loadTeachers = () => {
    const teachersArr = [];
    const coTeachersArr = [];

    teachers.map((t) =>
      teachersArr.push({
        id: t.id,
        name: `${t.first_name} ${t.last_name}`,
      })
    );

    if (activeKlass && !activeKlass.teacher.is_active) {
      teachersArr.push({
        id: activeKlass?.teacher_id,
        name: `${activeKlass?.teacher.first_name} ${activeKlass?.teacher.last_name}`,
      });
    }

    teachers
      .filter((teacher) => teacher.id !== Number(watch("teacher")))
      .map((t) =>
        coTeachersArr.push({
          id: t.id,
          name: `${t.first_name} ${t.last_name}`,
        })
      );

    setActiveTeachers(teachersArr);
    setCoTeachers(coTeachersArr);
  };

  useEffect(() => {
    loadTeachers();
  }, [teachers, watch("teacher")]);

  useEffect(() => {
    setFilteredSubjectCourses([]);

    if (level === "elementary") {
      setFilteredCourses(
        courses
          .filter((course) => course.level === "elementary")
          .sort((a, b) => a.name.localeCompare(b.name))
      );
    } else if (level === "secondary") {
      setFilteredCourses(
        courses
          .filter(
            (course) => course.level === "secondary" && course.active === true
          )
          .sort((a, b) => a.name.localeCompare(b.name))
      );
      if (activeKlass) {
        const filteredCoursesArray = filteredCourses.filter(
          (course) =>
            course.level === "secondary" &&
            course.active === true &&
            course.subject_id === activeKlass?.subject?.id
        );
        const preSelectedCourse = filteredCourses.find(
          (course) => course.id === activeKlass?.course?.id
        );
        if (
          !filteredCoursesArray.find(
            (course) => course.id === preSelectedCourse.id
          )
        ) {
          filteredCoursesArray.push(preSelectedCourse);
        }
        setFilteredSubjectCourses(filteredCoursesArray);
      }
    }
  }, [level, courses, subjects, activeKlass]);

  return (
    <>
      <Box sx={[klassFormDivider, pt0]}>
        <FormControl sx={klassFormRow}>
          <RadioGroup
            row
            aria-labelledby="level"
            name="level"
            value={level}
            onChange={(e) => {
              setLevel(e.target.value);
            }}
          >
            <FormControlLabel
              disabled={activeKlass && true}
              value="elementary"
              control={<Radio />}
              label="Elementary"
            />
            {currentSchool.has_secondary_students && (
              <FormControlLabel
                disabled={activeKlass && true}
                value="secondary"
                control={<Radio />}
                label="Secondary"
              />
            )}
            {currentSchool.has_pk_tracker && (
              <FormControlLabel
                disabled={activeKlass && true}
                value="pk"
                control={<Radio />}
                label="PK Attendance"
              />
            )}
          </RadioGroup>
        </FormControl>
        <Grid container sx={klassFormRow} spacing={2}>
          <Grid item xs={8}>
            {level !== "pk" && (
              <>
                {level === "elementary" && (
                  <>
                    <FormControl fullWidth error={errors?.course && true}>
                      <InputLabel id="course" shrink sx={bgWhite}>
                        Subject*
                      </InputLabel>
                      <Select
                        labelId="course"
                        id="course"
                        defaultValue={activeKlass?.course?.id || ""}
                        {...register("course", {
                          required: requiredMessage,
                          onChange: (e) => setSelectedSubject(e.target.value),
                        })}
                      >
                        {filteredCourses.map((course) => (
                          <MenuItem key={course.id} value={course.id}>
                            {course.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {errors?.course && renderError(errors?.course.message)}
                  </>
                )}

                {level === "secondary" && (
                  <>
                    <FormControl
                      fullWidth
                      error={errors?.subject && true}
                      sx={pb20}
                    >
                      <InputLabel id="subject" shrink sx={bgWhite}>
                        Subject*
                      </InputLabel>
                      <Select
                        labelId="subject"
                        id="subject"
                        defaultValue={activeKlass?.subject?.id || ""}
                        {...register("subject", {
                          required: requiredMessage,
                          onChange: (e) => {
                            setSelectedSubject(e.target.value);
                            setFilteredSubjectCourses(
                              filteredCourses.filter(
                                (course) => course.subject_id === e.target.value
                              )
                            );
                          },
                        })}
                      >
                        {subjects.map((subject) => (
                          <MenuItem key={subject.id} value={subject.id}>
                            {subject.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors?.subject && renderError(errors?.subject.message)}

                    <FormControl fullWidth error={errors?.course && true}>
                      <InputLabel id="course" shrink sx={bgWhite}>
                        Course*
                      </InputLabel>
                      <Select
                        labelId="course"
                        id="course"
                        defaultValue={activeKlass?.course?.id || ""}
                        {...register("course", {
                          required: requiredMessage,
                        })}
                      >
                        {filteredSubjectCourses.map((course) => (
                          <MenuItem key={course?.id} value={course?.id}>
                            {course?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors?.course && renderError(errors?.course.message)}
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>

        <Grid container sx={klassFormRow} spacing={2}>
          <Grid item xs={8}>
            <ErasableTextField
              id="name"
              label="Name of class*"
              fullWidth
              isRequired
              type="text"
              defaultValue={activeKlass?.name || ""}
              hasError={errors?.name && true}
            />

            {errors?.name && renderError(errors?.name.message)}
          </Grid>

          <Grid item xs={4}>
            <ErasableTextField
              id="abbreviation"
              label="Abbreviation*"
              fullWidth
              isRequired
              type="text"
              defaultValue={activeKlass?.abbreviation || ""}
              hasError={errors?.abbreviation && true}
            />

            {errors?.abbreviation && renderError(errors?.abbreviation.message)}
          </Grid>
        </Grid>

        {level !== "pk" && (
          <Grid container sx={klassFormRow} spacing={2}>
            <Grid item xs={4}>
              <ErasableTextField
                fullWidth
                id="roomNumber"
                label="Room Number"
                type="text"
                defaultValue={activeKlass?.room_number || ""}
                hasError={errors?.roomNumber && true}
              />

              {errors?.roomNumber && renderError(errors?.roomNumber.message)}
            </Grid>

            <Grid item xs={4}>
              <ErasableTextField
                fullWidth
                id="capacity"
                label="Capacity"
                type="number"
                defaultValue={activeKlass?.capacity || ""}
                hasError={errors?.capacity && true}
              />

              {errors?.capacity && renderError(errors?.capacity.message)}
            </Grid>
          </Grid>
        )}
      </Box>

      <Box sx={{ pt: "25px" }}>
        <Box>
          <FormControl fullWidth error={errors?.teacher && true}>
            <InputLabel id="teacher" shrink sx={bgWhite}>
              Teacher*
            </InputLabel>
            {teachers.length > 0 && (
              <Select
                labelId="teacher"
                id="teacher"
                defaultValue={activeKlass?.teacher_id || teacherId}
                {...register("teacher", {
                  required: requiredMessage,
                })}
              >
                {activeTeachers.map((teacher) => (
                  <MenuItem key={teacher.id} value={teacher.id}>
                    {teacher.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>

          {errors?.teacher && renderError(errors?.teacher.message)}
        </Box>

        {coTeachers.length > 0 && (
          <Box sx={{ pt: "25px" }}>
            <FormControl fullWidth error={errors?.teacher && true}>
              <InputLabel id="co-teacher-label" sx={bgWhite}>
                Co-teacher
              </InputLabel>
              <Controller
                name="co_teacher"
                control={control}
                defaultValue={activeKlass?.co_teacher_id || ""}
                render={({ field: { onChange, value } }) => (
                  <Select
                    labelId="co-teacher-label"
                    id="co-teacher"
                    label="Co-teacher"
                    defaultValue={activeKlass?.co_teacher_id}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    {...register("co_teacher")}
                    endAdornment={
                      <IconButton sx={clearIconStyle(value)}>
                        <CancelOutlinedIcon
                          onClick={() => {
                            resetField("co_teacher", {
                              defaultValue: "",
                              keepDirty: false,
                            });
                          }}
                        />
                      </IconButton>
                    }
                    sx={arrowDownIconStyle(value)}
                  >
                    {coTeachers.map((teacher) => (
                      <MenuItem key={teacher.id} value={teacher.id}>
                        {teacher.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            {errors?.teacher && renderError(errors?.teacher.message)}
          </Box>
        )}

        <Box sx={{ pt: "25px" }}>
          <ErasableTextField
            id="meeting_url"
            label="Meeting URL"
            fullWidth
            type="text"
            defaultValue={activeKlass?.meeting_url || ""}
          />
        </Box>

        <Box sx={klassFormDivider}>
          <FormControlLabel
            control={
              <Checkbox
                {...register("klass_attendance")}
                defaultChecked={activeKlass?.klass_attendance}
              />
            }
            label="Enable class attendance"
          />
        </Box>
      </Box>
    </>
  );
}
