import React, { useEffect, useState, useContext } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import {
  Container,
  Stack,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  Collapse,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  codeBarContainer,
  attendanceTeacherSelection,
  attendanceKlassName,
  attendancePeriod,
} from "./Attendance.styles";
import {
  selectDropdown,
  selectDropdownOpen,
} from "../components/SelectDropdown.styles";
import CodeBar from "../components/Attendance/CodeBar";
import AttendanceTable from "../components/Attendance/AttendanceTable";
import moment from "../utils/constants/momentConfig";
import attendanceService from "../service/attendanceService";
import dateTimeFormats from "../utils/constants/dateTimeFormats";
import schoolDayService from "../service/schoolDayService";
import staffsService from "../service/staffsService";
import klassesService from "../service/klassesService";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";
import { font28, font18, mr10 } from "../components/sharedStyles";
import KlassSelect from "../components/Attendance/KlassSelect";
import { parseJwt, storedToken } from "../utils/constants/auth";

export default function Attendance() {
  const viewOptions = [
    {
      id: "Day",
      name: "Day Attendance",
    },
    {
      id: "Week",
      name: "Week Attendance",
    },
    {
      id: "Class",
      name: "Class Attendance",
    },
  ];

  const { hasPermission, hasAnyPermissionType } =
    useContext(PermissionsContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState("Day");
  const [students, setStudents] = useState([]);
  const [hasAnyKlass, setHasAnyKlass] = useState(false);
  const [klasses, setKlasses] = useState([]);
  const [homeroomTeachers, setHomeroomTeachers] = useState([]);
  const [substituteTeachers, setSubstituteTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedSubstituteTeacher, setSelectedSubstituteTeacher] =
    useState(null);
  const [markingCodes, setMarkingCodes] = useState([]);
  const [activeCode, setActiveCode] = useState(null);
  const [schoolDays, setSchoolDays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [showUnenrolled, setShowUnenrolled] = useState(false);
  const params = useParams();
  const schoolId = params.school_id;
  const currentStaff = parseJwt(storedToken).staff;

  const manageAllStudentsPermission = hasPermission(
    APP_PERMISSIONS.ALL_STUDENTS,
    PERMISSION_TYPES.MANAGE
  );

  const isDayView = view !== "Week";
  const isClassView = searchParams.get("mode") === "Class";
  const selectedKlasses = klasses.filter((k) => k.is_active).map((k) => k.id);

  const momentValue = moment(searchParams.get("day")).isValid()
    ? searchParams.get("day")
    : {};

  const [currentDay, setCurrentDay] = useState(moment(momentValue));

  const getKlasses = async () => {
    const response = await attendanceService.getKlasses({
      params: {
        date: searchParams.get("day") || moment().format("YYYY-MM-DD"),
        homeroom_teacher_id: selectedTeacher,
      },
    });
    if (response.data) {
      setKlasses(response.data.klasses);
    }
  };

  const getAllKlasses = async () => {
    const response = await klassesService.fetchAllKlasses();

    if (response.data) {
      setHasAnyKlass(
        response.data.klasses.filter((k) => k.klass_attendance).length > 0
      );
    }
  };

  const shouldShowTableForSubstitute = (date) => {
    if (selectedSubstituteTeacher === null) return true;
    return selectedSubstituteTeacher.teaching_substitutes.some((substitute) => {
      const beginningDate = moment
        .utc(substitute.beginning_date)
        .startOf("day");
      const endDate = moment
        .utc(substitute.end_date)
        .add(1, "days")
        .startOf("day");
      const currentDayAdjusted = moment.utc(date).startOf("day");
      return currentDayAdjusted.isBetween(beginningDate, endDate, null, "[)");
    });
  };

  const getStudents = async (date) => {
    setLoading(true);
    const dbStudents = await attendanceService.getAttendance(date, {
      params: {
        school_id: schoolId,
        homeroom_teacher_id: selectedTeacher,
        klasses: isClassView ? selectedKlasses : null,
        show_unenrolled: showUnenrolled ? true : null,
        is_substitute: selectedSubstituteTeacher !== null,
      },
    });

    setStudents(dbStudents.filter((n) => n));
    setLoading(false);
  };

  const getTeachers = async () => {
    const dbTeachers = await staffsService.fetchHomeroomTeachers({
      params: { school_id: schoolId },
    });
    setHomeroomTeachers(dbTeachers);
  };

  const getSubstituteTeachers = async () => {
    const dbSubstituteTeachers = await staffsService.fetchSubstituteTeachers();
    setSubstituteTeachers(dbSubstituteTeachers.data);
  };

  const getCodes = async () => {
    const codes = await attendanceService.getAttendanceMarkingCodes({
      params: { school_id: schoolId },
    });
    const markingCodesArr = [
      {
        code: "-",
        color: "#EBF0F1",
        index: 0,
      },
    ];

    codes.map((c, index) => {
      const markedCode = { ...c, index: index + 1 };
      return markingCodesArr.push(markedCode);
    });

    setActiveCode(
      markingCodesArr.filter((c) => c.code === searchParams.get("code"))[0] ||
        markingCodesArr[1]
    );
    setMarkingCodes(markingCodesArr);
  };

  const getSchoolDays = async () => {
    const response = await schoolDayService.getSchoolDaysBySchool({
      params: { school_id: schoolId },
    });
    if (response) {
      const currentDayIndex = response.data.findIndex(
        (day) =>
          day.date === moment(momentValue).format(dateTimeFormats.YYYYMMDD)
      );
      let nextSchoolDay = null;
      if (currentDayIndex === -1) {
        nextSchoolDay = response.data.find(
          (day) =>
            moment(day.date, "YYYY-MM-DD").isAfter(momentValue, "day") &&
            (day.school_day_designation?.category === "school_day" ||
              day.day_template_id !== null)
        );
      } else if (
        response.data[currentDayIndex].school_day_designation?.category !==
          "school_day" &&
        response.data[currentDayIndex].day_template_id === null
      ) {
        nextSchoolDay = response.data.find(
          (day) =>
            moment(day.date, "YYYY-MM-DD").isAfter(momentValue, "day") &&
            (day.school_day_designation?.category === "school_day" ||
              day.day_template_id !== null)
        );
      } else {
        setCurrentDay(moment(response.data[currentDayIndex].date));
      }

      if (nextSchoolDay) {
        setCurrentDay(moment(nextSchoolDay.date));
      }

      setSchoolDays(response.data);
    }
  };

  const handleViewChange = (mode) => {
    searchParams.set("mode", mode);
    setView(mode);
    setSearchParams(searchParams);
    setSelectOpen(false);
  };

  const handleTeacherSelect = (value) => {
    const teacherId = value === "all" ? null : value;
    setSelectedTeacher(teacherId);
  };

  const handleSubstituteTeacherSelect = (subTeacher) => {
    if (subTeacher) {
      setSelectedTeacher(subTeacher.id);
      setSelectedSubstituteTeacher(subTeacher);
    } else {
      setSelectedTeacher(null);
      setSelectedSubstituteTeacher(null);
    }
  };

  useEffect(() => {
    Promise.all([getCodes(), getSchoolDays(), getSubstituteTeachers()]);

    if (isClassView) {
      Promise.all([getKlasses()]);
    }

    if (manageAllStudentsPermission) {
      Promise.all([getTeachers()]);
    }
  }, [view, selectedTeacher, showUnenrolled]);

  useEffect(() => {
    const isSchoolDaysAvailable = schoolDays[0] !== undefined;
    if (isSchoolDaysAvailable) {
      getStudents(currentDay.format(dateTimeFormats.YYYYMMDD));
      getKlasses();
    }
  }, [currentDay]);

  useEffect(() => {
    if (
      searchParams.get("mode") === "Day" ||
      searchParams.get("mode") === "Week" ||
      searchParams.get("mode") === "Class"
    ) {
      setView(searchParams.get("mode"));
    } else {
      setView("Day");
    }
  }, []);

  useEffect(() => {
    if (isClassView && klasses.length > 0) {
      getStudents(currentDay.format(dateTimeFormats.YYYYMMDD));
    }

    if (klasses.length === 0) {
      setStudents([]);
    }
  }, [klasses, isClassView]);

  useEffect(() => {
    getAllKlasses();
  }, []);

  const currentWeek = moment(momentValue).startOf("weeks");
  const endOfCurrentWeek = moment(momentValue).endOf("week");
  const range = moment.range(currentWeek, endOfCurrentWeek);
  const weekDays = Array.from(range.by("days"));

  const activeCodes = markingCodes
    .slice(1)
    .filter((c) => c.is_active)
    .map((c, index) => ({ ...c, index: index + 1 }));

  const activeViewMode = viewOptions.find(
    (viewMode) => viewMode.id === view
  ).name;

  const currentTime = moment().utc();
  const activeKlass = klasses.find((klass) => klass.is_active);

  const activePeriod = activeKlass?.periods.find((p) =>
    moment(p.end_time, "LT").isSameOrAfter(currentTime, "LT")
  );

  const renderKlassSelect = () => {
    if (manageAllStudentsPermission && selectedTeacher === null) {
      return false;
    }

    return (
      <KlassSelect
        klasses={klasses}
        setKlasses={setKlasses}
        manageAllStudentsPermission={manageAllStudentsPermission}
      />
    );
  };

  const renderPeriod = () => {
    if (manageAllStudentsPermission && selectedTeacher === null) {
      return false;
    }

    return (
      <>
        <Typography sx={attendanceKlassName}>{activeKlass?.name}</Typography>
        <Typography sx={attendancePeriod}>
          {activePeriod?.name || "Period"},{" "}
          {`${moment.utc(activePeriod?.start_time).format("LT")} - ${moment
            .utc(activePeriod?.end_time)
            .format("LT")}`}
        </Typography>
      </>
    );
  };

  return (
    <Container maxWidth="md1280" sx={{ mt: "65px" }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box sx={{ height: "55px" }}>
            <Box sx={selectOpen ? selectDropdownOpen : selectDropdown}>
              <Stack
                direction="row"
                alignItems="center"
                onClick={() => setSelectOpen(!selectOpen)}
              >
                <Typography sx={font28} color="primary">
                  {activeViewMode}
                </Typography>
                {selectOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Stack>

              <Collapse in={selectOpen} timeout={150}>
                {viewOptions
                  .filter(
                    (option) =>
                      option.id !== view &&
                      (option.id !== "Class" || hasAnyKlass)
                  )
                  .map((mode) => (
                    <Typography
                      key={mode.id}
                      sx={font18}
                      color="primary"
                      onClick={() => handleViewChange(mode.id)}
                    >
                      {mode.name}
                    </Typography>
                  ))}
              </Collapse>
            </Box>
          </Box>

          {markingCodes.length > 0 &&
            hasAnyPermissionType(APP_PERMISSIONS.ATTENDANCE) && (
              <Box sx={codeBarContainer}>
                <CodeBar
                  availableCodes={activeCodes}
                  activeCode={activeCode}
                  setActiveCode={setActiveCode}
                />
              </Box>
            )}
        </Stack>
        <Box>
          {manageAllStudentsPermission && (
            <FormControl fullWidth sx={[attendanceTeacherSelection, mr10]}>
              <Select
                id="homeroom-teacher"
                defaultValue="all"
                onChange={(e) => handleTeacherSelect(e.target.value)}
              >
                <MenuItem value="all">
                  {isClassView ? "Select" : "All Teachers"}
                </MenuItem>
                {homeroomTeachers.map((ht) => (
                  <MenuItem key={ht.id} value={ht.id}>
                    {`${ht.first_name} ${ht.last_name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {isClassView && renderKlassSelect()}

          <FormControlLabel
            control={
              <Checkbox onChange={(e) => setShowUnenrolled(e.target.checked)} />
            }
            label="Show unenrolled students"
          />

          {!manageAllStudentsPermission &&
            substituteTeachers &&
            substituteTeachers.length > 0 && (
              <FormControl fullWidth sx={[attendanceTeacherSelection, mr10]}>
                <Select
                  id="homeroom-teacher"
                  defaultValue={currentStaff.id}
                  onChange={(e) => {
                    const selectedTeacherId = e.target.value;
                    const subTeacher = substituteTeachers.find(
                      (teacher) => teacher.id === selectedTeacherId
                    );
                    handleSubstituteTeacherSelect(subTeacher);
                  }}
                >
                  <MenuItem
                    value={currentStaff.id}
                  >{`${currentStaff.first_name} ${currentStaff.last_name}`}</MenuItem>
                  {substituteTeachers.map((ht) => (
                    <MenuItem key={ht.id} value={ht.id}>
                      {`${ht.first_name} ${ht.last_name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
        </Box>
      </Stack>

      {isClassView &&
        klasses.length > 0 &&
        klasses.filter((k) => k.is_active === true).length === 1 && (
          <Stack justifyContent="end" direction="row" alignItems="end">
            {renderPeriod()}
          </Stack>
        )}

      {markingCodes.length > 0 &&
      hasAnyPermissionType(APP_PERMISSIONS.ATTENDANCE) ? (
        <AttendanceTable
          availableCodes={markingCodes}
          activeCode={activeCode}
          weekDays={weekDays}
          currentDay={currentDay}
          isDayView={isDayView}
          students={students}
          schoolDays={schoolDays}
          view={view}
          setCurrentDay={setCurrentDay}
          selectedTeacher={selectedTeacher}
          loading={loading}
          isClassView={isClassView}
          selectedKlasses={selectedKlasses}
          showUnenrolled={showUnenrolled}
          manageAllStudentsPermission={manageAllStudentsPermission}
          shouldShowTableForSubstitute={shouldShowTableForSubstitute}
        />
      ) : (
        <Typography align="center">
          {`You don't have permission to view this page.`}
        </Typography>
      )}
    </Container>
  );
}
